<template lang="pug">
.om-onboarding-agency-contact.mb-6.mb-md-0
  wizard-top.mb-4.mb-md-5(:show-progress="false")
  .container
    .row
      .d-none.d-lg-flex.col-lg-1
      .col-3
        om-monks.mb-4.mb-md-0(monk="hello-agency")
      .om-onboarding-agency-contact-container.col-9.col-lg-7.pb-5
        .om-onboarding-agency-contact-title {{ $t('onboarding.agencyContact.title') }}
        .om-onboarding-agency-contact-card
          .left
            om-unique-icon(name="contact" :size="48")
          .right
            .full-name {{ `${firstName} ${lastName}` }}
            .email {{ email }}
        .om-onboarding-agency-contact-subtitle {{ $t('onboarding.agencyContact.subTitle') }}
        om-select-cards.w-100(v-model="contactOwner" :options="contactOwnerOptions")
      .d-none.d-lg-flex.col-lg-1
</template>

<script>
  import { mapState } from 'vuex';
  import WizardTop from '@/components/Wizard/Top.vue';
  import WizardTitle from '@/components/Wizard/Title.vue';
  import SAVE_AGENCY_POTENTIAL from '@/graphql/SaveAgencyPotential.gql';
  import InlineSvg from 'vue-inline-svg';
  import { accountSetupRouteNames } from '@/router/routes/accountSetupRoutes';
  import { track } from '@/services/xray';
  import navigationMixin from '../accountSetup-navigation';

  export default {
    components: { WizardTop, WizardTitle, InlineSvg },
    mixins: [navigationMixin],

    data() {
      return {
        contactOwner: null,
      };
    },

    computed: {
      ...mapState(['account']),
      email() {
        return this.account.login.email;
      },
      firstName() {
        return this.account.login.firstName;
      },
      lastName() {
        return this.account.login.lastName;
      },
      contactOwnerOptions() {
        const obj = this.$t('onboarding.agencyContact.options');

        return Object.keys(obj).map((k) => ({
          value: k,
          key: obj[k].title,
          details: obj[k].description,
        }));
      },
    },

    watch: {
      contactOwner() {
        this.onOptionClick();
      },
    },

    methods: {
      async onOptionClick() {
        track('onboarding-owner-of-contact', { answer: this.contactOwner });
        if (this.contactOwner === 'client-contact') {
          this.$router.push({ name: accountSetupRouteNames.AGENCY_CONTACT_DETAILS });
          return;
        }

        await this.saveAgencyPotential();
        await this.finish();

        this.$router.push({ name: 'dashboard' });
      },
      async saveAgencyPotential() {
        try {
          await this.$apollo
            .mutate({
              mutation: SAVE_AGENCY_POTENTIAL,
            })
            .catch(() => {
              this.$notify({
                type: 'error',
                title: this.$t('notifications.saveError'),
              });
            });

          return true;
        } catch (_) {
          return false;
        }
      },
    },
  };
</script>
<style lang="sass">
  .om-onboarding-agency-contact
    &-container
      max-width: 472px
    &-title
      font-weight: 700
      font-size: 24px
      margin-bottom: 1rem
    &-subtitle
      margin-bottom: 1rem
      font-size: 16px
    &-card
      display: flex
      padding: 12px 16px
      gap: 12px
      border: 1px solid #D5D8DD
      border-radius: 8px
      margin-bottom: 1rem
      .left
        display: flex
        justify-content: center
        align-items: center
      .full-name
        font-weight: 500
        font-size: 20px
        line-height: 32px
      .email
        color: #8F97A4
        font-size: 12px
        line-height: 20px
  @media screen and (max-width: 1399px)
    .om-onboarding-agency-contact
      .brand-select-card-with-details
          height: 80px
</style>
